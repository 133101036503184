.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #7B8084;
  border-radius: 30px;
  color: #7B8084;
  height: 36px;
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  letter-spacing: -0.5px;
  width: 100%;
}

.iconDown {
  margin-left: 8px;
}
