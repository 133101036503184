@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  padding: 0;

  & .scrollLeft,
  & .scrollRight {
    flex-shrink: 0;
    border: 1px solid var(--colorDefaultGray);
    border-radius: 50%;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--marketplaceGreen);

      & svg path {
        fill: var(--colorWhite);
      }
    }
  }

  & .scrollLeft {
    & svg {
      transform: rotate(90deg);
    }
  }

  & .scrollRight {
    & svg {
      transform: rotate(-90deg);
    }
  }
}

.wrapper {
  /* overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-wrap: nowrap;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 5px;

  margin: 0;
  width: calc(100vw - 116px);
  
  @media (--viewportMedium) {
    width: auto;
  }
}

.item {
  padding: 4px 0 2px 0;
  flex-shrink: 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.text {
  font-family: var(--buttonFontFamily);
  line-height: 22px;
  font-size: 12px;
  font-weight: normal;
}

.label {
  background: #F6F9F9;
  border: 1px solid #F6F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 50px;
  height: 34px;
  margin-right: 10px;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    border: 1px solid #335A50;
    cursor: pointer;
  }
}

.rootClassName {

}

.boxClassName {

}

.checkbox {

}

.customInput {

  &:checked + label  {
    border: 1px solid #335A50;
    background: #f2fbf7;
  }
}
