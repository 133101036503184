.root {
  display: flex;
  flex-direction: column;
  /*position: fixed;*/
  /*top: var(--topbarHeight);*/
  /*left: 0;*/
  z-index: calc(var(--zIndexTopbar) - 1);
  background: white;
  /* width: 200%; */
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.searchOptionsTitle {
  font-family: var(--fontFamily);
  font-weight: bold;
  font-size: 55px;
  line-height: 70px;
  letter-spacing: -1px;
  margin-bottom: 16px;
  margin-top: 67px;

  @media (--viewportLarge) {
    font-size: 64px;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.selectedFiltersHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 7px;
}

.selectedFiltersTitle {
  font-size: 18px;
}

.selectedFiltersList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.selectedFiltersItem {
  position: relative;
  align-items: center;
  justify-content: stretch;
  display: flex;
  background: var(--colorSuccess);
  border-radius: 50px;
  color: white;
  padding: 0 30px 0 12px;
  margin: 0 8px 8px 0;
  height: 26px;
  font-weight: normal;
  text-transform: none;
  font-family: var(--buttonFontFamily);
  font-size: 14px;
  line-height: 18px;
}

.closeIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1000;
}
