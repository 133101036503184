@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.labelPlain {
  color: var(--colorGrey700);
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlainInput {

  & input {
    border: 1px solid #7B8084;
    border-radius: 30px;
    color: #7B8084;
    height: 36px;
    font-family: var(--fontFamily);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;

    &:hover,
    &:focus {
      border-color: var(--colorGrey300);
    }
  }

}

.fieldPlain {
  composes: h4 from global;
  margin: 0;
  /*padding: 16px 0 30px 12px;*/
  border: none;

  @media (--viewportMedium) {
    /*padding: 16px 0 24px 12px;*/
  }
}

.fieldPlainLabel {
  padding: 4px 0 4px 0;
}

.iconSearch {
  position: absolute;
  top: calc(50% + 14px);
  right: 20px;
  transform: translateY(-50%);
  display: none;

  @media (--viewportMedium) {
    top: 50%;
  }

  @media (--viewportCustom) {
    display: block;
  }
}
